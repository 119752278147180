import React, {useEffect} from 'react'
import HeroSection from '../HeroSection'
import firebase from "firebase"
const Home = () => {

    const auth = async() => {
        await firebase.auth().signInAnonymously().catch(function(error) {
            console.log("User Authentication Error");
         }).then();
    }

    useEffect(() => {
             
        auth();
        
        return () => {
            
        }
    }, [])

    return (
            <HeroSection/>
       
    )
}

export default Home
