import React, {useState, useEffect} from 'react'
import Navbar from '../Navbar/index';
import './hero.css'
import {Link} from 'react-router-dom'
import db from '../../db'

const HeroSection = () => {
    const [grade, setGrade] = useState(0)
    useEffect(() => {
        db.collection('SportGrade').doc('sport-grade-counter').onSnapshot(snap => {
            setGrade(snap.data().total_graded);
            // console.log(snap.data().total_graded);
         })
        return () => {
            
        }
    }, [])
    return (
       <div className="hero-head"> 


        <div className="hero">
             <Navbar className="nav"/>
            <div className="hero-content">
                <h1 className="first"> HOW GOOD ARE YOU AT SPORT?</h1>
                <h1 className="second">Take our Quick (10) Question Quiz </h1>
                <h1 className="second">and Get your Universal Sports Number (USN)</h1>
                <p className="pick">Pick Your Sport</p>
                   <div className="HeroBtnWrapper">
                       <Link className="btn" to="/get-your-utn">
                        Tennis
                       </Link>
                       <Link className="btn" to="/get-your-usn">
                        Soccer
                       </Link>
                       <br/>
                       <br/>
                   </div>
                   <p className="grade">{grade} Graded So Far</p>
                   <p className="created">Created by Stewart Whicker</p>
            </div>
        </div>
        </div>
    )
}

export default HeroSection
