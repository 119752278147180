import React from 'react'
import './progress.css';
function ProgressCompleted() {
    return (
        <div>
            <div className="progressBar">
			<div className="progressBar-done">
				
			</div>
		</div>
        </div>
    )
}

export default ProgressCompleted
