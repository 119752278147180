import React, { useState } from 'react'
import '../css/quiz.css'
import logo from '../Images/Sports.svg';
import { Link } from 'react-router-dom'
import db from '../../db';



// TODO: Pass Props from previous page
function SigninPage({ match }) {

    const {sportName, usn} = match.params;
    // console.log(sportName);
    // console.log(usn);
    // console.log(match.params);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");


    const onHandleSubmit = () => {

        if (name !== "" && email !== "") {
            // Storing the signup data form to firebase
            const refernce = db.collection('SignUpForm').doc();
            refernce.set({
                sportName,
                name,
                email,
                usn
            });
        }



    }

    return (
        <div className="form-page">
            <div className="logo-header">
                <Link to="/">
                    <img src={logo} alt="" className="logo" />
                </Link>
            </div>
            <form className="form">
                <h3 className="strong">Thank you for Opting in</h3>
                <p>We don't spam, it is not our style</p>
                <p>You can opt out anytime</p>
                <input type="text" placeholder="Name" onChange={event => setName(event.target.value)} />
                <input type="email" placeholder="Email" onChange={event => setEmail(event.target.value)} />
                <Link className="link" to="/">
                    <button className="submit" onClick={event => { onHandleSubmit() }}>Send</button>
                </Link>
            </form>
        </div>
    )
}

export default SigninPage
