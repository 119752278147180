import firebase from 'firebase';

// Stewart Firebase Config
const firebaseConfig = {
        apiKey: "AIzaSyDUC4pBHX32RQp-QJV8HAu6cClIBlUWDJ0",
        authDomain: "sportsgrading-2280a.firebaseapp.com",
        databaseURL: "https://sportsgrading-2280a.firebaseio.com",
        projectId: "sportsgrading-2280a",
        storageBucket: "sportsgrading-2280a.appspot.com",
        messagingSenderId: "471778107424",
        appId: "1:471778107424:web:151bdc8367955356171c45",
        measurementId: "G-FFR3QXQFBT"
      };

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore();
export default db;